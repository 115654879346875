<template>
  <ChangePasswordForm />
</template>

<script>
import ChangePasswordForm from '@/components/ChangePassword.vue';

export default {
  name: 'ChangePassword',
  components: {
    ChangePasswordForm,
  },
};
</script>
